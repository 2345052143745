import styled from 'styled-components';
import { colors } from '../../../colors';

export const GaleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0 10%;

  @media screen and (max-width: 768px) {
    padding: 0 1.8rem;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;

  @media screen and (max-width: 768px) {
    width: 48.5%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  p {
    font-size: 12px;
    margin-top: 5px;
    line-height: 1;
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  margin: 2.5rem 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
`;

export const ServicesIcon = styled.img`
  height: 55px;
  width: 50px;
  margin-bottom: 10px;
`;

export const ServicesH2 = styled.h2`
  font-size: 1.6rem;
  font-weight: 600;
  color: ${colors.darkGray};
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

export const PhotoDescription = styled.p`
  height: 0.3rem;
`;

export const ImplementationBtn = styled.button`
  font-size: 0.8rem;
  margin: 2px 2px;
  background: none;
  border: 1px solid blue;
  line-height: 1.1rem;
  padding: 7px;
  font-weight: 700;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2 ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

export const ImgImp = styled.img`
    object-fit: cover;
    width: 100%;
    height: auto;
`;
