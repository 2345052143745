import styled from 'styled-components';
import { Link } from 'react-scroll';
import { primaryStyles, secondaryStyles } from '../Button'

export const ButtonLink = styled(Link)`
    width: 8rem;
    white-space: nowrap;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-right: 10px;
  ${(props) => (props.secondary ? secondaryStyles : primaryStyles)};

  &:hover,
  &:focus {
    transition: all 0.2s ease-in-out;
  }

  &:active {
    transform: translateY(1px);
  }
`;

export default ButtonLink;
