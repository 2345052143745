import React from 'react'
import { LineElement } from './LineElements'

const Line = () => {
	return (
		<LineElement />
	)
}

export default Line
